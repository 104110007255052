// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@wix/yoshi-style-dependencies/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes _4H6FE{from{transform:translateY(-60px)}to{transform:translateY(0)}}.vRIku{position:fixed;top:0;width:100%;height:60px;z-index:9999;display:flex;font-family:Helvetica, Arial, sans-serif;align-items:center;background-color:#f1f1f1;box-shadow:0 2px 10px 1px rgba(0,0,0,0.15);will-change:transform;animation:_4H6FE 200ms linear}._9ZSMj{position:fixed !important;top:0;transform:translateY(60px)}._9ZSMj>div{height:calc(100vh - 60px);overflow-y:auto}.Z1HKE{transition:transform 200ms linear}.h93vc{transition:top 200ms linear;top:-60px}.Px5kR{flex:1;font-size:12px;line-height:18px;text-align:left;color:#000}.X-ljj{padding:14px;cursor:pointer;background:transparent;border:0}.X-ljj svg{width:13px;height:13px}.fw1c2{display:block;margin-right:14px;font-size:13px;color:#000;width:89px;height:28px;line-height:28px;border:2px solid #000;border-radius:16px;text-decoration:none;color:#000;background:#fdfdfd;text-align:center;font-weight:bolder}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animationDuration": "200ms",
	"bannerHeight": "60px",
	"banner": "vRIku",
	"slide-down": "_4H6FE",
	"slideDown": "_4H6FE",
	"site-container-push": "_9ZSMj",
	"siteContainerPush": "_9ZSMj",
	"site-container-push-animation": "Z1HKE",
	"siteContainerPushAnimation": "Z1HKE",
	"banner-remove": "h93vc",
	"bannerRemove": "h93vc",
	"text": "Px5kR",
	"close-button": "X-ljj",
	"closeButton": "X-ljj",
	"app-link": "fw1c2",
	"appLink": "fw1c2"
};
export default ___CSS_LOADER_EXPORT___;
